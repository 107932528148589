
























































































































































































































































import { computed, defineComponent, nextTick, ref, watch } from '@vue/composition-api';
import { useWysiwygSetting } from '@/composition/wysiwygSetting';
import { useNotification } from '@/composition/notification';
import { useBirthdayMessage } from '@/composition/birthdayMessages';
import FcDate from '@/components/FcDate.vue';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcAuthority from '@/components/FcAuthority.vue';
import FcWysiwyg from '@/components/FcWysiwyg.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { getStorageValue, removeStorage, saveStorage } from '@/admin/util';

export default defineComponent({
  name: 'BirthdayMessage',
  components: {
    FcDate,
    FcImage,
    FcStaticImage,
    FcAuthority,
    FcWysiwyg,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    notificationReservationId: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup({ notificationReservationId }, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('birthdayMessages'));
    const { birthdayMessage } = useBirthdayMessage(notificationReservationId);
    const wysiwygSetting = useWysiwygSetting();
    wysiwygSetting.fetchConsoleMenu();
    const { notify, error } = useNotification();

    const isSaving = ref(false); // 保存中のボタン押下制御
    const isLoading = ref(true); // ロードの制御
    const isFetchEditItem = ref(!!notificationReservationId); // 編集する投稿の読み込みか
    const isOpenDialog = ref(false); // ストレージデータ使用有無確認ダイアログ
    // storageデータ取得・格納
    const storageValue = getStorageValue<'birthday'>(birthdayMessage.storageKey);
    const type = context.root.$route.query?.type ? context.root.$route.query.type.toString() : '';
    const reservationStatusOptions = [
      {
        value: 'draft',
        text: '下書き',
      },
      {
        value: 'scheduled',
        text: '公開予約',
      }
    ];

    // dialogのボタン挙動
    function agree() {
      if (storageValue) birthdayMessage.setStorageValue(storageValue.item);
      isLoading.value = false;
      isOpenDialog.value = false;
    }

    async function disagree() {
      removeStorage(birthdayMessage.storageKey);
      await birthdayMessage.getBirthdayMessage();
      isLoading.value = false;
      isOpenDialog.value = false;
    }

    function getTypoNicknames(body?: string): string[] {
      if (!body) return [];
      const bodyTypoNicknames = body.match(/\{*nickname\}*/g)?.filter((nickname) => nickname !== '{{nickname}}');
      return bodyTypoNicknames ? bodyTypoNicknames : [];
    }

    async function saveMessage() {
      if (!confirm('本当に保存しますか？')) return;
      // nicknameのタイポチェック
      const body01_typoNicknames = getTypoNicknames(birthdayMessage.item.body01);
      if (
        body01_typoNicknames.length > 0 &&
        !confirm(
          `本文1に\n${body01_typoNicknames.join(
            ','
          )}がありますが、\n{{nickname}}の誤入力ではないですか？\n誤入力の場合は「キャンセル」を押して修正してください。`
        )
      )
        return;
      const body02_typoNicknames = getTypoNicknames(birthdayMessage.item.body02);
      if (
        body02_typoNicknames.length > 0 &&
        !confirm(
          `本文2に\n${body02_typoNicknames.join(
            ','
          )}がありますが、\n{{nickname}}の誤入力ではないですか？\n誤入力の場合は「キャンセル」を押して修正してください。`
        )
      )
        return;

      isSaving.value = true;
      try {
        if (notificationReservationId) {
          await birthdayMessage.update();
          notify('変更を保存しました。');
          // 通知予約で更新した場合
          if (birthdayMessage.item.notificationReservationStatus === 'scheduled') {
            // 入力不可にする。
            birthdayMessage.isAllowInput = false;
          }
          // birthdayMessage.isAllowInputはwatchの監視対象になっているため、birthdayMessage.isAllowInputの変更でストレージ保管処理が走る
          // ストレージの削除処理の後に保管処理が走るのを防ぐため、削除処理はDOM更新を待ち、watchが動いた後に行う
          await nextTick();
          removeStorage(birthdayMessage.storageKey);
        } else {
          await birthdayMessage.create();
          removeStorage(birthdayMessage.storageKey);
          notify('新しい通知を作成しました。');
          context.root.$router.push('/birthday');
        }
      } catch (e) {
        error(e);
      }
      isSaving.value = false;
    }

    async function saveChangeStatus() {
      if (!confirm('予約を解除してステータスを下書きに変更します。よろしいですか？')) return;
      isSaving.value = true;
      try {
        // 2つの非同期処理それぞれに対しwatchが走りストレージ保持されるので、処理の前にフラグを立てる
        isFetchEditItem.value = true;
        await birthdayMessage.updateDraftFromScheduled();
        isFetchEditItem.value = true;
        await birthdayMessage.getBirthdayMessage();
        notify('ステータスを下書きに変更しました。');
      } catch (e) {
        error(e);
      } finally {
        isFetchEditItem.value = false;
      }
      isSaving.value = false;
    }

    async function init() {
      if (storageValue) birthdayMessage.setStorageValue(storageValue.item);
      // 新規作成もしくは下書き更新時に対象のstorageデータが存在する場合、ストレージのデータを使うか確認する
      isOpenDialog.value = (!type || type === 'draft') && !!storageValue;
      // ダイアログによる確認が必要ない場合、(あれば)ストレージデータの削除 + 初期データの格納を行う
      if (!isOpenDialog.value) {
        removeStorage(birthdayMessage.storageKey);
        await birthdayMessage.getBirthdayMessage();
        isLoading.value = false;
      }
    }

    init();

    // データ編集・リロード時にストレージに保存
    watch(
      () => [birthdayMessage.item],
      () => {
        if (isFetchEditItem.value) {
          // 編集データの読み込み時はストレージ保管しない
          isFetchEditItem.value = false;
        } else if (!isLoading.value) {
          // ストレージ保管
          saveStorage<'birthday'>(birthdayMessage.storageKey, birthdayMessage.getNewStorageValue());
        }
      },
      {
        deep: true,
      }
    );

    watch(
      () => [birthdayMessage.item.isExternalLink],
      () => {
        if (!birthdayMessage.item.isExternalLink) {
          birthdayMessage.item.linkText = undefined;
          birthdayMessage.item.linkUrl = undefined;
        }
      }
    );

    return {
      pageTitle: '誕生日メッセージテンプレート',
      myRoleSettings,
      wysiwygSetting,
      birthdayMessage,
      storageValue,
      reservationStatusOptions,
      isSaving,
      saveMessage,
      saveChangeStatus,
      isLoading,
      isOpenDialog,
      agree,
      disagree,
    };
  },
});
